import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StoreState } from "models/state/StoreState";
import { NotificationState } from "models/state/NotificationState";
import { NotificationModel } from "models/Notification";
import { getNotifications } from "services/api/notificationApi";

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    isLoading: false,
    notifications: [],
    hasUnreadNotification: false,
  } as NotificationState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setUnreadNotification(state, action) {
      state.hasUnreadNotification = action.payload;
    },
  },
});

export const getNotificationRequest = createAsyncThunk(
  "notification/getNotificationRequest",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;

    dispatch(notificationSlice.actions.setIsLoading(true));
    const notification = await getNotifications(accessToken);
    dispatch(notificationSlice.actions.setNotifications(notification));
    dispatch(notificationSlice.actions.setIsLoading(false));
    dispatch(notificationSlice.actions.setUnreadNotification(false));
  }
);

// Reducers
export const getLoadingStatus = (state: StoreState): boolean =>
  state.notifications.isLoading;
export const getAllNotifications = (state: StoreState): NotificationModel[] =>
  state.notifications.notifications;
export const getHasUnreadNotificationStatus = (state: StoreState): boolean =>
  state.notifications.hasUnreadNotification;

export const { setNotifications, setUnreadNotification, setIsLoading } =
  notificationSlice.actions;
export default notificationSlice.reducer;
