import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Campaign from "layouts/campaign";
// @mui icons
import Icon from "@mui/material/Icon";
import CampaignDetails from "./layouts/campaign/campaignDetails";
import Profile from "layouts/profile";
import ContactUs from "layouts/contactUs";
import ForgetPassword from "layouts/authentication/reset-password";
import Finance from "./layouts/finance/index";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Campaigns",
    key: "campaigns",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/campaigns",
    component: <Campaign />,
  },
  {
    type: "details",
    name: "Campaigns Details",
    key: "campaigns",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/campaigns/:id",
    component: <CampaignDetails />,
  },
  {
    type: "collapse",
    name: "Finance",
    key: "finance",
    icon: <Icon fontSize="small">account_balance_wallet</Icon>,
    route: "/finance",
    component: <Finance />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Contact Us",
    key: "contact-us",
    icon: <Icon fontSize="small">call</Icon>,
    route: "/contact-us",
    component: <ContactUs />,
  },
  {
    type: "details",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "details",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: <SignUp />,
  },
  {
    type: "details",
    name: "Forget Password",
    key: "forget-password",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/forget-password",
    component: <ForgetPassword />,
  },
];

export default routes;
