import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useEffect } from "react";

type Props = {
  onChangeDatesRange: (from: number, to: number) => void;
};
const DatePickerRange: React.FC<Props> = (prop) => {
  const { onChangeDatesRange } = prop;

  const [fromvalue, setfromValue] = useState<string | null>(null);
  const [toValue, setToValue] = useState<string | null>(null);

  useEffect(() => {
    if (!!fromvalue && !!toValue) {
      const fromTime = new Date(fromvalue).getTime();
      const toTime = new Date(toValue).getTime();
      onChangeDatesRange(fromTime, toTime);
    }
  }, [fromvalue, onChangeDatesRange, toValue]);

  const resetDateRange = () => {
    setfromValue(null);
    setToValue(null);
    onChangeDatesRange(0, 0);
  };

  return (
    <MDBox
      sx={{
        display: "flex",
      }}
      mt={1}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MDBox ml={1} mr={1}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            Filter:
          </MDTypography>
        </MDBox>
        <DatePicker
          label="From"
          value={fromvalue}
          onChange={(newValue) => {
            setfromValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <MDBox ml={1} mr={1}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            to
          </MDTypography>
        </MDBox>

        <DatePicker
          label="To"
          value={toValue}
          onChange={(newValue) => {
            setToValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <MDBox ml={2} mr={2}>
          <MDTypography
            variant="button"
            color="primary"
            fontWeight="medium"
            sx={{ cursor: "pointer", userSelect: "none" }}
            textGradient
            onClick={resetDateRange}
          >
            Reset
          </MDTypography>
        </MDBox>
      </LocalizationProvider>
    </MDBox>
  );
};

export default DatePickerRange;
