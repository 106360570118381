import { combineReducers } from "redux";

import campaignSlice from "../slices/campaignSlice";
import authenticationSlice from "../slices/authenticationSlice";
import notificationSlice from "../slices/notificationSlice";

const rootReducer = combineReducers({
  campaigns: campaignSlice,
  authentication: authenticationSlice,
  notifications: notificationSlice,
});

export default rootReducer;
