import React from "react";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { Campaign } from "models/Campaign";

type Prop = {
  campaign: Campaign;
  children: React.ReactNode;
};
const CampaignDetailsHeader: React.FC<Prop> = ({ campaign, children }) => {
  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        bgColor={campaign.banner_url ? "none" : "secondary"}
        sx={{
          backgroundImage: `url(${campaign.banner_url})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar
              src={campaign.logo_url}
              alt="profile-image"
              size="xl"
              shadow="sm"
            />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {campaign.title}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {campaign.brand ? campaign.brand.brand_name : ""}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
};
export default CampaignDetailsHeader;
