import React, { useState, useCallback } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useDispatch } from "react-redux";
import { forgetAccountPassword } from "redux/slices/authenticationSlice";
import { Link, useNavigate } from "react-router-dom";

const ForgetPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleForgetPassword = useCallback(() => {
    if (!!email) {
      dispatch(forgetAccountPassword(email));
      alert("A reset passoword link will send to your entered email shortly");
      navigate("/dashboard");
    } else {
      alert("Please fill in the email field");
    }
  }, [email, dispatch, navigate]);

  return (
    <CoverLayout coverHeight="50vh">
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                //variant="standard"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                fullWidth
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="primary"
                onClick={handleForgetPassword}
                fullWidth
              >
                reset
              </MDButton>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography
                component={Link}
                to="/sign-in"
                color="primary"
                variant="button"
                fontWeight="medium"
                textGradient
              >
                Back to Login
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default ForgetPassword;
