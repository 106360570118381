import { AxiosResponse } from "axios";
import { getRequest } from "services/client/request";
import { NotificationModel, NotificationResponses } from "models/Notification";

const apiVersion = "/api";

export const getNotifications = async (
  accessToken: string
): Promise<NotificationModel[]> => {
  const response: AxiosResponse<NotificationResponses> = await getRequest(
    `${apiVersion}/notifications`,
    accessToken
  );
  const notification: NotificationModel[] = response.data.data
    ? response.data.data
    : [];
  return notification;
};
