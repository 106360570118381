import UserContext from "hooks/usePageContext";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsAuthenticatedStatus,
  getUserDetails,
  validateAuthentication,
  getLoginFailed,
  logout,
} from "redux/slices/authenticationSlice";
import LoadingComponent from "components/LoadingComponent";

const WithAuthWrapper = <T,>(
  WrappedComponent: React.ComponentType<T>
): React.FC<T> => {
  const Layout = (props: T) => {
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const user = useSelector(getUserDetails);
    const loginFailed = useSelector(getLoginFailed);
    const accessToken = localStorage.getItem("carching-token");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
      // Initialse
      if (!!accessToken) {
        dispatch(validateAuthentication(accessToken));
      } else {
        dispatch(logout());
        navigate("/sign-in");
      }
    }, [accessToken, dispatch, navigate]);

    useEffect(() => {
      // Validation
      if (!!loginFailed) {
        dispatch(logout());
        navigate("/sign-in");
      }
    }, [dispatch, loginFailed, navigate]);

    return (
      <React.Fragment>
        {!isAuthenticated && <LoadingComponent />}
        {isAuthenticated && !!accessToken && user && (
          <UserContext.Provider
            value={{
              user: user,
            }}
          >
            <WrappedComponent {...props} />
          </UserContext.Provider>
        )}
      </React.Fragment>
    );
  };
  return Layout;
};

export default WithAuthWrapper;
