const rows = [
  {
    months: "December 2021",
    amount: "RM24,000.00",
    status: "Paid",
    date: "12/12/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    months: "November 2021",
    amount: "RM24,000.00",
    status: "Paid",
    date: "12/11/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    months: "October 2021",
    amount: "RM24,000.00",
    status: "Paid",
    date: "12/10/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    months: "September 2021",
    amount: "RM24,000.00",
    status: "Outstanding",
    date: "12/09/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    months: "August 2021",
    amount: "RM24,000.00",
    status: "Invoiced",
    date: "12/08/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    months: "July 2021",
    amount: "RM24,000.00",
    status: "Paid",
    date: "12/07/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    months: "June 2021",
    amount: "RM24,000.00",
    status: "Paid",
    date: "12/06/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    months: "May 2021",
    amount: "RM24,000.00",
    status: "Invoiced",
    date: "12/05/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
  {
    months: "April 2021",
    amount: "RM24,000.00",
    status: "Paid",
    date: "12/04/20",
    pdf: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
  },
];

export default rows;
