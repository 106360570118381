import React, { useState, useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";
import { getNotificationToken, onMessageListener } from "../../firebase";
import { useDispatch } from "react-redux";
import { setUnreadNotification } from "redux/slices/notificationSlice";

const Notification: React.FC = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });

  const [successSB, setSuccessSB] = useState(false);
  const openNotificationSnackBar = () => setSuccessSB(true);
  const closeNotificationSnackBa = () => setSuccessSB(false);
  const dispatch = useDispatch();

  const renderSuccessSB = (
    <MDSnackbar
      color="primary"
      icon="check"
      title={notification.title}
      content={notification.body}
      dateTime="now"
      open={successSB}
      onClose={closeNotificationSnackBa}
      close={closeNotificationSnackBa}
      bgWhite
    />
  );

  useEffect(() => {
    getNotificationToken();
  }, []);

  useEffect(() => {
    if (notification?.title) {
      openNotificationSnackBar();
    }
  }, [dispatch, notification]);

  onMessageListener()
    .then((payload: any) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
      dispatch(setUnreadNotification(true));
    })
    .catch((err) => console.log("failed: ", err));

  return <div>{renderSuccessSB}</div>;
};

export default Notification;
