import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";

type LoadingComponentProps = {
  colour?: "primary" | "secondary" | "inherit";
};

const LoadingComponent: React.FC<LoadingComponentProps> = (props) => {
  const { colour } = props;

  return (
    <MDBox
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ position: "fixed", top: 0, bottom: 0, right: 0, left: 0 }}
    >
      <CircularProgress color={colour} />
    </MDBox>
  );
};

export default LoadingComponent;

LoadingComponent.defaultProps = {
  colour: "primary",
};
