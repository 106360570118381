import { AxiosResponse } from "axios";
import { getRequest } from "services/client/request";
import {
  Campaign,
  CampaignDetailResponses,
  CampaignsResponses,
} from "models/Campaign";

const apiVersion = "/api";

export const getCampaigns = async (
  accessToken: string
): Promise<Campaign[]> => {
  const response: AxiosResponse<CampaignsResponses> = await getRequest(
    `${apiVersion}/campaigns`,
    accessToken
  );
  const campaigns: Campaign[] = response.data.data.data
    ? response.data.data.data
    : [];

  return campaigns;
};

export const getSpecificCampaign = async (
  accessToken: string,
  campaignId: string
): Promise<Campaign> => {
  const response: AxiosResponse<CampaignDetailResponses> = await getRequest(
    `${apiVersion}/campaigns/${campaignId}`,
    accessToken
  );
  const campaigns: Campaign = response.data.data
    ? response.data.data
    : ({} as Campaign);

  return campaigns;
};
