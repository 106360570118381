import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingComponent from "components/LoadingComponent";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import brandWhite from "assets/images/logo.png";
import {
  loginRequest,
  getIsAuthenticatedStatus,
  getLoadingStatus,
} from "redux/slices/authenticationSlice";
import { LoginRequest } from "models/User";

const LoginComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(getIsAuthenticatedStatus);
  const isLoading = useSelector(getLoadingStatus);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const handleLoginSubmit = useCallback(() => {
    if (email !== "" && password !== "" && !!email && !!password) {
      const request: LoginRequest = {
        email: email,
        password: password,
      };
      dispatch(loginRequest(request));
    }
  }, [dispatch, email, password]);

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          >
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography
                component={MuiLink}
                href="#"
                variant="body1"
                color="white"
              >
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox
          component="img"
          src={brandWhite}
          alt="Brand"
          width="5rem"
          height="5rem"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          margin="auto"
        />
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                value={email}
                fullWidth
                onChange={(event) => setEmail(event.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                value={password}
                fullWidth
                onChange={(event) => setPassword(event.target.value)}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="primary"
                fullWidth
                onClick={handleLoginSubmit}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography
                component={Link}
                to="/forget-password"
                variant="button"
                fontWeight="regular"
                color="primary"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Forget Password
              </MDTypography>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        {isLoading && <LoadingComponent />}
      </Card>
    </BasicLayout>
  );
};

export default LoginComponent;
