import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import mockRow from "./mockdata";
import { Icon } from "@mui/material";
import { saveAs } from "file-saver";

export default function data() {
  const getStatusColor = (status: string): string => {
    if (status === "Outstanding") {
      return "dark";
    } else if (status === "Invoiced") {
      return "secondary";
    }
    return "primary";
  };
  const downloadPdf = (pdfLink: string, pdfName: string) => {
    saveAs(pdfLink, pdfName);
  };

  const formatStatusCell = (rowData): any[] => {
    return rowData.map((rowValue) => {
      return {
        months: rowValue.months,
        amount: rowValue.amount,
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={rowValue.status}
              color={getStatusColor(rowValue.status)}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        date: rowValue.date,
        download: (
          <MDBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            ml={3}
            sx={{ cursor: "pointer" }}
            onClick={() => downloadPdf(rowValue.pdf, rowValue.months)}
          >
            <Icon fontSize="small">picture_as_pdf</Icon>
            <MDTypography variant="button" fontWeight="regular">
              &nbsp;Pdf
            </MDTypography>
          </MDBox>
        ),
      };
    });
  };

  return {
    columns: [
      { Header: "months", accessor: "months", align: "left" },
      { Header: "amount", accessor: "amount", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "date", accessor: "date", align: "center" },
      { Header: "download", accessor: "download", align: "center" },
    ],
    rows: formatStatusCell(mockRow),
  };
}
