import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Footer from "features/Footer";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import CampaignDetailsHeader from "./Header";
import MDTypography from "components/MDTypography";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaign,
  getLoadingStatus,
  getCampaignDetails,
} from "redux/slices/campaignSlice";
import { useLocation } from "react-router-dom";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import LoadingComponent from "components/LoadingComponent";

const CampaignDetails: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const campaign = useSelector(getCampaignDetails);
  const isLoading = useSelector(getLoadingStatus);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    if (!!accessToken) {
      dispatch(getCampaign({ campaignId: route[1], accessToken }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, dispatch, route[1]]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {!!campaign && (
            <CampaignDetailsHeader campaign={campaign}>
              <MDBox mt={5} mb={3}>
                <MDBox
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                >
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Campaign Information
                  </MDTypography>
                  <MDBox mt={2} lineHeight={1}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="light"
                    >
                      {campaign.desc}
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} xl={4}>
                    <MDBox display="flex" p={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Status: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        &nbsp; {campaign.status ? campaign.status.name : "none"}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                    <MDBox display="flex" p={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Max Participants: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        &nbsp; {campaign.max_participants}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                    <MDBox display="flex" p={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Total Budget: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        &nbsp; RM200,000
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                    <MDBox display="flex" p={2}>
                      <MDTypography
                        variant="button"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Total Budget: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        &nbsp; RM200,000
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </CampaignDetailsHeader>
          )}
        </>
      )}

      <Footer />
    </DashboardLayout>
  );
};

export default WithAuthWrapper(CampaignDetails);
