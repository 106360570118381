import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// custom styles for the NotificationItem
import menuItem from "./styles";

const MenuNotificationItems = forwardRef<any, any>(
  ({ icon, title, desc, ...rest }, ref) => (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
      <MDBox
        component={Link}
        py={0.5}
        display="flex"
        alignItems="center"
        lineHeight={1}
      >
        <MDBox
          display="flex"
          lineHeight={1.125}
          mr={1}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="body1" color="secondary" lineHeight={1.125}>
            {icon}
          </MDTypography>
        </MDBox>

        <MDBox lineHeight={1.125}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {title}
          </MDTypography>
          <MDTypography variant="caption" fontWeight="regular" color="text">
            {desc}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MenuItem>
  )
);

// Typechecking props for the NotificationItem
MenuNotificationItems.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default MenuNotificationItems;
