import React, { useEffect } from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import DefaultProjectCard from "features/Cards/ProjectCards";
// Material Dashboard 2 React example components
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllCampaings,
  getAllCampaignsThunk,
  getLoadingStatus,
} from "redux/slices/campaignSlice";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import { Tab, Tabs } from "@mui/material";
import LoadingComponent from "components/LoadingComponent";

const Campaign: React.FC = () => {
  const accessToken = localStorage.getItem("carching-token");
  const dispatch = useDispatch();
  const allCampaigns = useSelector(getAllCampaings);
  const isLoading = useSelector(getLoadingStatus);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (!!accessToken && allCampaigns.length <= 0) {
      dispatch(getAllCampaignsThunk(accessToken));
    }
  }, [accessToken, allCampaigns.length, dispatch]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={2}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Active Campaigns" />
          <Tab label="Pending Campaigns" />
          <Tab label="Past Campaigns" />
        </Tabs>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {value === 0 && (
              <MDBox mt={5}>
                <Grid container spacing={6}>
                  {!!allCampaigns &&
                    allCampaigns.length > 0 &&
                    allCampaigns.map((campaign, index) => {
                      return (
                        <Grid item xs={12} md={6} xl={3} key={index}>
                          <DefaultProjectCard
                            image={campaign.logo_url}
                            label={campaign.start_date}
                            title={campaign.title}
                            description={campaign.desc}
                            action={{
                              type: "internal",
                              route: `/campaigns/${campaign.id}`,
                              color: "info",
                              label: "view campaign",
                            }}
                            authors={[]}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </MDBox>
            )}
          </>
        )}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default WithAuthWrapper(Campaign);
