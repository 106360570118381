import axios, { AxiosResponse } from "axios";

const baseUrl = "https://friday.carching.co";

const createAuthHeader = (token: string) => {
  if (!token) {
    throw new Error("Token is missing");
  }
  return {
    Authorization: `Bearer ${token}`,
  };
};

const createHeaders = (token: string): { [key: string]: string | boolean } => {
  const authHeader = createAuthHeader(token);
  const headers = {
    ...authHeader,
  };
  return headers;
};

export const postRequestWithoutToken = async (
  endpoints: string,
  data?: unknown
): Promise<AxiosResponse> => {
  return await axios.post(`${baseUrl}${endpoints}`, data);
};

export const postRequest = async (
  endpoints: string,
  token: string,
  data?: unknown
): Promise<AxiosResponse> => {
  return await axios.post(`${baseUrl}${endpoints}`, data, {
    headers: createHeaders(token),
  });
};

export const getRequest = async (
  endpoints: string,
  token: string,
  params?: unknown
): Promise<AxiosResponse> => {
  return await axios.get(`${baseUrl}${endpoints}`, {
    params: params,
    headers: createHeaders(token),
  });
};

export const putRequest = async (
  endpoints: string,
  token: string,
  data?: unknown
): Promise<AxiosResponse> => {
  return await axios.put(`${baseUrl}${endpoints}`, data, {
    headers: createHeaders(token),
  });
};

export const deleteRequest = async (
  endpoints: string,
  token: string,
  params?: unknown
): Promise<AxiosResponse> => {
  return await axios.delete(`${baseUrl}${endpoints}`, {
    params: params,
    headers: createHeaders(token),
  });
};
