import React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import countries from "./countries";
import MDInput from "..";

type Props = {
  error: boolean;
  onChangePhoneNumber: (phoneNumber: string) => void;
  onChangeCountryCode: (code: string) => void;
};

const MDPhoneIput: React.FC<Props> = (props) => {
  const { error, onChangePhoneNumber, onChangeCountryCode } = props;
  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={4}>
          <MDBox>
            <Autocomplete
              onChange={(_, newValue) => {
                onChangeCountryCode(newValue!!.phone);
              }}
              id="country-select-demo"
              options={countries}
              size="medium"
              autoHighlight
              getOptionLabel={(option) => option.phone}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.code}>
                  {option.label} ({option.code}) +{option.phone}
                </Box>
              )}
              renderInput={(params) => <MDInput {...params} label="+(60)" />}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <MDBox>
            <MDInput
              type="number"
              label="Phone Number"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              onChange={(event) => onChangePhoneNumber(event.target.value)}
              error={error}
              fullWidth
            />
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};
export default MDPhoneIput;
