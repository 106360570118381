import React from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import Footer from "features/Footer";
import ComplexStatisticsCard from "features/Cards/StatisticsCards";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import LineChart from "features/Charts/LineCharts";
import MDButton from "components/MDButton";
import HeatMap from "../../features/Charts/Heatmap/index";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <LineChart
          height={350}
          colors={["#E0479E", "#FEE440", "#6DE2D4"]}
          series={[
            {
              name: "Campaign 1",
              data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
            },
            {
              name: "Campaign 2",
              data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
            },
            {
              name: "Campaign 3",
              data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
            },
          ]}
          strokeWidth={2}
          title={"Estimated Impression accumulated from all campaigns"}
          description={"Line graph of period (X)"}
          icon={{ color: "primary", component: "leaderboard" }}
        />
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}>
            <Card sx={{ height: "100%" }}>
              <MDBox
                pt={2}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" fontWeight="medium">
                  Current Campaign&apos;s
                </MDTypography>
                <MDButton
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    navigate("/campaigns/1");
                  }}
                >
                  More Details
                </MDButton>
              </MDBox>

              <MDBox pt={1} pb={5} px={2}>
                <MDBox mt={0.25}>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="medium"
                  >
                    Progress:
                  </MDTypography>

                  <MDProgress
                    variant="gradient"
                    color={"primary"}
                    value={70}
                    label
                  />
                </MDBox>
              </MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5} mt={1.5}>
                    <ComplexStatisticsCard
                      color="success"
                      icon="event"
                      title="Status of Campaign"
                      count={"Pending"}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5} mt={1.5}>
                    <ComplexStatisticsCard
                      icon="directions_car"
                      color="warning"
                      title="Number of Active Cars"
                      count="2,300"
                      percentage={{
                        color: "success",
                        amount: "+3%",
                        label: "than last month",
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5} mt={1.5}>
                    <ComplexStatisticsCard
                      color="primary"
                      icon="paid"
                      title="Estimated Budget spent"
                      count="34k"
                      percentage={{
                        color: "success",
                        amount: "+1%",
                        label: "than yesterday",
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <MDBox mb={1.5} mt={1.5}>
                    <ComplexStatisticsCard
                      color="secondary"
                      icon="av_timer"
                      title="Estimated Impression"
                      count="500km"
                      percentage={{
                        color: "success",
                        amount: "",
                        label: "Just updated",
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}>
            <Card sx={{ height: "100%" }}>
              <MDBox pt={3} px={2}>
                <HeatMap />
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default WithAuthWrapper(Dashboard);
