import React, { useEffect, useRef } from "react";
import DashboardLayout from "features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "features/Navbars/DashboardNavbar";
import L from "leaflet";
import WithAuthWrapper from "withWrapper/withAuthWrapper";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography/index";

const ContactUs: React.FC = () => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  useEffect(() => {
    mapRef.current = L.map("map", {
      center: [3.147858, 101.69904],
      zoom: 16,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });

    markerRef.current = L.marker({ lat: 3.147858, lng: 101.69904 }).addTo(
      mapRef.current
    );
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <MDBox
          mb={2}
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
        >
          <div
            id="map"
            style={{
              width: "100%",
              height: "50vh",
              zIndex: -10,
            }}
          />
        </MDBox>
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
            boxShadow: "none",
          }}
        >
          <MDBox
            justifyContent="space-between"
            alignItems="center"
            pt={2}
            px={2}
          >
            <MDTypography
              variant="h3"
              fontWeight="medium"
              textTransform="capitalize"
            >
              Contact Us
            </MDTypography>
            <MDBox mt={2} lineHeight={1}>
              <MDTypography variant="button" color="text" fontWeight="light">
                Get in touch and let us know how we can help
              </MDTypography>
            </MDBox>
            <MDBox display="flex" mt={2}>
              <MDTypography
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Email: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp; 321go@carching.co
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};
export default WithAuthWrapper(ContactUs);
