import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import userIcon from "assets/images/user.png";
import { useSelector } from "react-redux";
import { getUserDetails } from "redux/slices/authenticationSlice";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const Header = ({ children }) => {
  const [image, setImage] = useState({ preview: "", raw: "" });

  const user = useSelector(getUserDetails);
  const [tabsOrientation, setTabsOrientation] = useState<
    "horizontal" | "vertical" | undefined
  >("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleUpload = async (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="30rem"
        borderRadius="xl"
        bgColor="primary"
        sx={{
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -35,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <label htmlFor="upload-button">
              <MDAvatar
                src={!!image && image.preview !== "" ? image.preview : userIcon}
                alt="profile-image"
                size="xl"
                shadow="sm"
                onClick={handleUpload}
              />
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange}
              />
              <MDBox sx={{ float: "right", cursor: "pointer" }} mt={-1.9}>
                <PhotoCameraIcon color={"primary"} />
              </MDBox>
            </label>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {`${user.fname} ${user.lname}`}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {user.email}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
};

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
