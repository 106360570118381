import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StoreState } from "models/state/StoreState";
import { Campaign } from "models/Campaign";
import { CampaignState } from "../../models/state/CampaignState";
import {
  getCampaigns,
  getSpecificCampaign,
} from "../../services/api/campaignApi";

const campaignSlice = createSlice({
  name: "dashboard",
  initialState: {
    campaigns: [] as Campaign[],
    campaign: {},
    loading: true,
  } as CampaignState,
  reducers: {
    setAllCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setLoadingStatus(state, action) {
      state.loading = action.payload;
    },
    setCampaign(state, action) {
      state.campaign = action.payload;
    },
  },
});
export const getLoadingStatus = (state: StoreState): boolean =>
  state.campaigns.loading;
export const getAllCampaings = (state: StoreState): Campaign[] =>
  state.campaigns.campaigns;

export const getCampaignDetails = (state: StoreState): Campaign =>
  state.campaigns.campaign;

export const getAllCampaignsThunk = createAsyncThunk(
  "campaign/getAllCampaignsThunk",
  async (accessToken: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const campaigns = await getCampaigns(accessToken);
    dispatch(campaignSlice.actions.setAllCampaigns(campaigns));
    dispatch(campaignSlice.actions.setLoadingStatus(false));
  }
);
type CampaignRequest = {
  campaignId: string;
  accessToken: string;
};
export const getCampaign = createAsyncThunk(
  "campaign/getCampaign",
  async (campaignRequest: CampaignRequest, thunkApi) => {
    const { dispatch } = thunkApi;
    const { campaignId, accessToken } = campaignRequest;
    dispatch(campaignSlice.actions.setLoadingStatus(true));
    const campaigns = await getSpecificCampaign(accessToken, campaignId);
    dispatch(campaignSlice.actions.setCampaign(campaigns));
    dispatch(campaignSlice.actions.setLoadingStatus(false));
  }
);

export const { setAllCampaigns } = campaignSlice.actions;
export default campaignSlice.reducer;
