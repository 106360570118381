import { createStore, applyMiddleware, Store } from "redux";
import thunkMiddleware, { ThunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers/rootReducer";

const bindMiddleware = (middleware: [ThunkMiddleware]) => {
  if (process.env.NODE_ENV !== "production") {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const initializeStore = (): Store =>
  createStore(rootReducer, bindMiddleware([thunkMiddleware]));
export const store = initializeStore();
