import React, { useMemo } from "react";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import Chart from "react-apexcharts";
import { Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import DatePickerRange from "components/MDDatePicker/index";

type LineChartProps = {
  colors: string[];
  series: any[];
  height?: string | number;
  strokeWidth?: number;
  title: string;
  description: string;
  icon?: {
    color: string;
    component: string;
  };
};

const LineChart: React.FC<LineChartProps> = (props) => {
  const { title, description, colors, series, height, strokeWidth, icon } =
    props;

  const handleDateRangeUpdate = (from: number, to: number) => {
    if (from === 0 || to === 0) {
      //reset from first date to last date
      ApexCharts.exec("linechart", "updateSeries", series);
    } else {
      ApexCharts.exec("linechart", "zoomX", from, to);
    }
  };
  return (
    <Card>
      <MDBox py={2} pr={2} pl={icon?.component ? 1 : 2}>
        {title || description ? (
          <MDBox
            display="flex"
            px={description ? 1 : 0}
            pt={description ? 1 : 0}
          >
            {icon && icon.component && (
              <MDBox
                width="4rem"
                height="4rem"
                bgColor={icon.color || "info"}
                variant="gradient"
                coloredShadow={icon.color || "info"}
                borderRadius="xl"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
                mt={-5}
                mr={2}
              >
                <Icon fontSize="medium">{icon.component}</Icon>
              </MDBox>
            )}
            <MDBox
              mt={icon?.component ? -2 : 0}
              display={{ xs: "block", lg: "flex" }}
              sx={{
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <MDBox mb={2}>
                {title && <MDTypography variant="h6">{title}</MDTypography>}
                <MDTypography component="div" variant="button" color="text">
                  {description}
                </MDTypography>
              </MDBox>
              <MDBox>
                <DatePickerRange onChangeDatesRange={handleDateRangeUpdate} />
              </MDBox>
            </MDBox>
          </MDBox>
        ) : null}
        {useMemo(
          () => (
            <MDBox height={height}>
              <Chart
                series={series}
                height={height}
                options={{
                  colors: colors,
                  chart: {
                    id: "linechart",
                    height: 350,
                    type: "line",
                  },
                  stroke: {
                    width: strokeWidth,
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  yaxis: {
                    title: {
                      text: "Estimated Impression",
                    },
                  },
                  xaxis: {
                    type: "datetime",
                    tickAmount: "dataPoints",
                    tooltip: {
                      enabled: false,
                    },
                    labels: {
                      formatter: function (value, timestamp) {
                        if (timestamp) {
                          const dateValue = new Date(value);
                          return `${
                            dateValue.getMonth() + 1
                          }/ ${dateValue.getFullYear()}`;
                        }
                        return "no time";
                      },
                    },
                    categories: [
                      1640948400000, 1643626800000, 1646046000000,
                      1648724400000, 1651320000000, 1653998400000,
                      1656590400000, 1659268800000, 1661947200000,
                    ],
                  },
                }}
                type="line"
              />
            </MDBox>
          ),
          [height, series, colors, strokeWidth]
        )}
      </MDBox>
    </Card>
  );
};

export default LineChart;
